import { graphql } from "gatsby"
import { Link, useStaticQuery } from "gatsby"
import React, { useEffect, useState, useContext } from "react"
import MainLayout from "../components/layouts/main-layout.js"
import Seo from "../components/seo.js"
import Banner from "../components/hero/banner-hometeam.js"

import "react-lazy-load-image-component/src/effects/blur.css"

import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

import Hero from "../components/hero/hero-quickbase-security-audit.js"

import { SecurityData } from "../components/internal-data/security-data.js"

import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap"

import ItConsulting from "../components/security-audit.js"

import PartnerSwiper from "../components/swiper/swiper-partner.js"

import Testimonials2 from "../components/swiper/testimonial-swiper.js"

import UnlockEnterprise from "../components/unlock-enterprise.js"
import Cases from "../components/cases.js"

import CaseStudySection from "../components/header/header-casestudy-section.js"

const CaseComponent = ({ dataSrc }) => {
  return (
    <section id="case-studies-container">
      <div id="case-studies" className="bg-case-study py-5">
        <div className="container d-none">
          <div className="intro text-center">
            <div className="row">
              <div className="col-md-10 mx-auto">
                {/* <p
                  className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                  style={{ fontFamily: "Futura,sans-serif" }}
                >
                  Case Studies
                </p>
                <h2 className=" my-2 text-white">
                  We Save Clients 1000s of Hours. Every Year.
                </h2> */}
                {/* <p className="text-white">
                  We helped a national healthcare provider save over 9,000 hours
                  in weekly labor costs by developing a single application...
                </p> */}
              </div>
            </div>
          </div>
        </div>
        {dataSrc && <Cases allSanityCaseStudy={dataSrc} />}
        <div className="text-center">
          <Link
            className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
            to="/case-studies/"
          >
            More Case Studies
          </Link>
        </div>
      </div>
    </section>
  )
}

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Card.Header
      className={isCurrentEventKey ? "accordion-active" : "accordion-collapsed"}
    >
      <button
        aria-label="btn"
        type="button"
        onClick={decoratedOnClick}
        className={`m-0 border-0 bg-transparent w-100 text-left ${
          isCurrentEventKey ? "text-primary-gradient" : ""
        }`}
      >
        <p className="h5 text-left toggle-btn">{children}</p>
        <div>
          <div className="btn-plus-minus" />
        </div>
      </button>
    </Card.Header>
  )
}

const QuickbaseSecurityAuditPage = props => {
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    function f2(ev) {
      ev.preventDefault()
    }

    // A function that invokes a callback when the page has unsaved changes.
    // window.addEventListener("beforeunload", f2)

    // A function that invokes a callback when the page's unsaved changes are resolved.
    // window.removeEventListener("beforeunload", f2)

    // document.body.addEventListener("click", f2, {
    //   capture: false,
    //   passive: true,
    // })
    // document.body.addEventListener("touchstart", f2, {
    //   passive: true,
    //   capture: false,
    // })
  }, [])
  const { data } = props

  return (
    <MainLayout location={props.location} dataSrc={data.allSanityCaseStudy}>
      <Seo
        title="Application Security Audit | Quandary Consulting Group"
        description="From small issues to potentially catastrophic failures, we help you find and prioritize your security vulnerabilities with our Quickbase security audit."
      />
      <div id="main">
        <Hero />
        <PartnerSwiper />
        <div id="it-consult" className="it-consult-scroll">
          <ItConsulting />
        </div>
        <section
          className="faq-wrapper security-audit"
          style={{ padding: "1.5rem 0 4.5rem" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-9 mx-auto">
                <h2 className="mt-0 text-center">
                  Our 10-Point Security Audit
                </h2>
                {SecurityData.map(account => (
                  <Accordion defaultActiveKey="0" key={account.id}>
                    <Card className="accordion-body">
                      <ContextAwareToggle
                        eventKey={account.id}
                        className="bg-transparent"
                      >
                        {account.title}
                      </ContextAwareToggle>
                      <Accordion.Collapse eventKey={account.id}>
                        <Card.Body>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: account.description,
                            }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ))}
              </div>
            </div>
          </div>
        </section>
        <Testimonials2 />
        <CaseStudySection />
        <CaseComponent dataSrc={data.allSanityCaseStudy} />
        <Banner />
        <UnlockEnterprise />
        <section
          className="bg-case-study slogan-section"
          style={{
            paddingTop: "5rem",
            paddingBottom: "5rem",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto text-center">
                <div>
                  <h2 className="text-white">
                    What Security Score Will Your Quickbase Applications Get?
                  </h2>
                  <Link
                    to="/contact/"
                    className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                    style={{ marginTop: "auto" }}
                  >
                    Let's Chat
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </MainLayout>
  )
}

export default QuickbaseSecurityAuditPage

export const QuickbaseSecurityAuditPagePageQuery = graphql`
  query QuickbaseSecurityAuditPagePageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
