import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "swiper/css/swiper.css"
import useMediaQuery from "../hooks/useMediaQuery"

const SecurityAudit = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")

  return (
    <section
      id="it-consulting"
      className="it-consulting"
      style={{ padding: "24px 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="my-3 font-weight-medium">
              Identify Risks. Prioritize Improvements. Protect Your Business,
              Team, And Clients
            </h2>
            <p
              className="text-center mx-auto px-sm-2"
              style={{ maxWidth: "825px" }}
            >
              Low code doesn’t mean low security. Our 10-point analysis helps
              you keep your entire Quickbase infrastructure secure.
            </p>
          </div>
        </div>
        {isDesktop ? (
          <LazyLoadComponent>
            <div class="tabs pt-4">
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab1"
                defaultChecked
              />
              <label
                for="tab1"
                class="tabs__label mx-auto text-center"
                style={{ width: "30%" }}
              >
                Find and Fix Security Risks
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/2b4a2abb8e7a10aec55b2eab74121a3bf5621df0-1920x1080.jpg"
                      alt="Find and Fix Security Risks"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">
                      Find and Fix Security Risks
                    </h3>
                    <p className="my-4 text-left-mobile">
                      Are your Quickbase applications compliant? Secure? Do the
                      right people have access? We find and help you fix
                      security weak points.
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab2"
              />
              <label
                for="tab2"
                class="tabs__label mx-auto text-center"
                style={{ width: "30%" }}
              >
                Training and Support
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-5 text-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Training and Support</h3>
                    <p className="my-4 text-left-mobile">
                      We train your team to properly review activities, vet
                      employees, and build systems for continued compliance and
                      application security.
                    </p>
                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/software-asset-management/"
                    >
                      Audit Your Tech Stack
                      <span className="d-none">
                        This is a redirection to quandary-connect
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-lg-6 col-md-6 my-lg-4 my-md-4 my-0 mt-lg-0 mt-md-0 mt-5">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/1fee49589b319a1a802047848c292abbe95711a9-1920x1280.jpg"
                      alt="Training and Support"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <input
                type="radio"
                class="tabs__radio"
                name="tabs-example"
                id="tab3"
              />
              <label
                for="tab3"
                class="tabs__label mx-auto text-center"
                style={{
                  width: "30%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Implementation Support
              </label>
              <div class="tabs__content">
                <div
                  className="row align-items-center mt-3 mb-5"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                >
                  <div className="col-lg-6 col-md-6 mt-4 mb-4">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/2847bf04ee4cf22d68e8e69e5642f1425826c2ea-1920x1440.jpg"
                      alt=" Implementation Support"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 text-lg-left text-md-left text-center align-self-center mb-lg-0 mb-md-0 mb-5 pl-md-5">
                    <h3 className="text-left-mobile">Implementation Support</h3>
                    <p className="my-4 text-left-mobile">
                      Using two-week sprints, we prioritize and fix the most
                      critical, high-value security improvements to your
                      Quickbase applications.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </LazyLoadComponent>
        ) : (
          <div className="mobile-swiper d-flex flex-column">
            <LazyLoadComponent>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Find and Fix Security Risks</h3>
                    <p className="my-4 ">
                      Are your Quickbase applications compliant? Secure? Do the
                      right people have access? We find and help you fix
                      security weak points.
                    </p>

                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/technology-consulting/"
                    >
                      Start Your Analysis
                      <span className="d-none">
                        This is a redirection to quandary-build
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-12">
                    {/* <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex w-100"
                        src="https://cdn.sanity.io/images/55lcecww/production/6a384fbfcf147dea7e9056c75dae53153353f6e3-1280x960.jpg?w=570"
                        alt="Application Development"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      /> */}
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100 img-fluid"
                      src="https://cdn.sanity.io/images/55lcecww/production/2b4a2abb8e7a10aec55b2eab74121a3bf5621df0-1920x1080.jpg"
                      alt="Find and Fix Security Risks"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Training and Support</h3>
                    <p className="my-4 ">
                      We train your team to properly review activities, vet
                      employees, and build systems for continued compliance and
                      application security.
                    </p>
                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/software-asset-management/"
                    >
                      Audit Your Tech Stack
                      <span className="d-none">
                        This is a redirection to quandary-connect
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-12">
                    <StaticImage
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/1fee49589b319a1a802047848c292abbe95711a9-1920x1280.jpg"
                      alt="Training and Support"
                      layout="constrained"
                      style={{ height: "20rem" }}
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-md-5 pb-md-5">
                <div>
                  <div className="col-12 text-center">
                    <h3 className="">Implementation Support</h3>
                    <p className="my-4 ">
                      Using two-week sprints, we prioritize and fix the most
                      critical, high-value security improvements to your
                      Quickbase applications.
                    </p>
                    {/* <Link
                      className="btn btn-primary btn-arrow"
                      to="/citizen-development-support/"
                    >
                      Find Your AI Gaps
                      <span className="d-none">
                        This is a redirection to Procurement Automation
                      </span>
                    </Link> */}
                  </div>
                  <div className="col-12">
                    <img
                      placeholder="blurred"
                      className="rounded d-flex w-100"
                      src="https://cdn.sanity.io/images/55lcecww/production/2847bf04ee4cf22d68e8e69e5642f1425826c2ea-1920x1440.jpg"
                      alt="Implementation Support"
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                    />
                  </div>
                </div>
              </div>
            </LazyLoadComponent>
          </div>
        )}
      </div>
    </section>
  )
}

export default SecurityAudit
