export const SecurityData = [
  {
    id: 1,
    title: "1. Vulnerability Assessment",
    description:
      "We identify security vulnerabilities based on data and system access to prevent potential breaches.",
    categories: "Account",
  },
  {
    id: 2,
    title: "2. Data Protection Analysis",
    description:
      "We ensure your sensitive data is stored properly, protected, and compliant with the relevant data privacy laws (HIPPA, SEC, PCI, etc.).",
    categories: "Account",
  },
  {
    id: 3,
    title: "3. Access Control Evaluation",
    description:
      "We evaluate user access controls to prevent unauthorized access to critical data from malicious partners or employees trying to work around your systems.",
    categories: "Account",
  },
  {
    id: 4,
    title: "4. Risk Assessment",
    description:
      "We identify and prioritize potential security risks on a client-by-client basis, providing a clear path to mitigate risk.",
    categories: "Account",
  },
  {
    id: 5,
    title: "5. Compliance Verification",
    description:
      "We ensure clients in industries with high compliance and security requirements satisfy those security standards and regulations.",
    categories: "Account",
  },
  {
    id: 6,
    title: "6. Incident Response Readiness",
    description:
      "We evaluate the strengths and weaknesses of your response plan and how well you can respond to security incidents.",
    categories: "Account",
  },
  {
    id: 7,
    title: "7. Security Best Practices Implementation",
    description:
      "We educate your team on best practices for user structuring and data management, collection, and protection in your Quickbase applications.",
    categories: "Account",
  },
  {
    id: 8,
    title: "8. Regular Security Updates",
    description:
      "We show your team how to review and adjust your systems based on Quickbase’s latest security patches and updates.",
    categories: "Account",
  },
  {
    id: 9,
    title: "9. Employee Training Recommendations",
    description:
      "We suggest training programs for staff to raise awareness around security best practices, reducing future risks to your organization.",
    categories: "Account",
  },
  {
    id: 10,
    title: "10. Detailed Security Report",
    description:
      "You get a comprehensive report detailing security findings and actionable recommendations. If you’re interested, we can help you implement them.",
    categories: "Account",
  },
]
